/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.form {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -210px 0 0 -210px;
  width: 320px;
  height: 420px;
  padding: 36px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
}
.form button {
  width: 100%;
}
.form p {
  color: #cccccc;
  text-align: center;
  margin-top: 16px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}
.logo {
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo img {
  width: 150px;
  margin-right: 8px;
}
.ant-spin-container,
.ant-spin-nested-loading {
  height: 100%;
}
